  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(".navbar-burger"),
    0
  );

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach(function($el) {
      $el.addEventListener("click", function() {
        // Get the "main-nav" element
        const $target = document.getElementById("main-nav");

        // Toggle the class on "main-nav"
        $target.classList.toggle("hidden");
      });
    });
  }

// Can also be included with a regular script tag
import Typed from "typed.js";

var options = {
  strings: [
    'Trading,^1000 <span class="text-white">the way it should have been.</span>\n<h1 class="text-white mt-1 text-6xl sm:text-6xl md:text-7xl lg:text-7xl xl:text-7xl font-bold leading-tight">^1000For you. For everyone.</h1>'
  ],
  typeSpeed: 60,
  backSpeed: 50,
  // time before backspacing
  backDelay: 2000,
  showCursor: false,
  cursorChar: "|",
  contentType: "html"
};

const typed = new Typed("#element", options);

const animateInvertedLeft = function(){
  const intersectionObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        //entry.target.style.WebkitTransform="rotateY(30deg) skewY(15deg)";
        if (entry.isIntersecting) {
          entry.target.style.transform = "rotateY(0deg) skewY(0deg)";
          intersectionObserver.unobserve(entry.target);
        } 
        
      });
    });
    
    const elements = [...document.querySelectorAll(".inverted-left")];
    elements.forEach(element => intersectionObserver.observe(element));
}

const animateInvertedRight = function(){
  const intersectionObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        //entry.target.style.WebkitTransform="rotateY(30deg) skewY(15deg)";
        if (entry.isIntersecting) {
          entry.target.style.transform = "rotateY(0deg) skewY(0deg)";
          intersectionObserver.unobserve(entry.target);
        } 
      });
    });
    
    const elements = [...document.querySelectorAll(".inverted-right")];
    elements.forEach(element => intersectionObserver.observe(element));
}

animateInvertedLeft();
animateInvertedRight();

